h1 {
	font-size: 50px;
	margin: 10px 0;
	font-weight: 900;
	font-family: "Bahnschrift";
}

h2 {
	font-size: 36px;
	margin: 10px 0;
	font-family: "Bahnschrift";
}

h3 {
	font-size: 32px;
	margin: 10px 0;
	font-family: "Bahnschrift";
}

h4 {
	font-size: 30px;
	margin: 10px 0;
	font-weight: 600;
	font-family: "Bahnschrift";
}

h5 {
	font-size: 28px;
	margin: 10px 0;
	font-family: "Bahnschrift";
}

h6 {
	font-size: 24px;
	margin: 10px 0;
	font-weight: 400;
	font-family: "Bahnschrift";
}

p {
	font-size: 20px;
	margin: 10px 0;
	font-family: "Bahnschrift";
}

.cont {
	padding: 120px 70px 0 70px;
}

.colm {
	padding: 0 70px;
	position: relative;
}

.row-cont {
	padding: 0 70px;
	position: relative;
}

.popup {
	&__list-li {
		display: flex;
		align-items: flex-start;
	}

	p {
		padding-left: 10px;
		text-align: flex-start;
	}

	&__line {
		margin: 3px 0;
		width: 100%;
		color: lightgrey;
	}

	// span {
	//   color: #0375b0;
	//   border-radius: 50%;
	//   padding-top: 15px;
	// }
	h6 {
		padding: 0 10px;
	}

	li {
		font-family: "Bahnschrift SemiBold";
	}
}

.col {
	padding: 0 100px;
}

.row-1 {
	&__container {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 40px;
	}

	&__content1 {
		h2 {
			margin: 0;
		}

		h3 {
			margin: 0;
		}

		p {
			margin: 0;
			padding-top: 5px;
		}
	}

	&__content {
		z-index: 20;
		width: 52%;
		display: flex;
		justify-content: center;
		background-color: #fff;
		padding: 60px 0px 60px 0px;
		border-radius: 40px 0 0 40px;
		position: relative;
		margin-top: 50px;

		p {
			font-size: 18px;
			font-weight: 700;
		}
	}

	&__image {
		width: 45%;
		display: flex;

		img {
			width: 100%;
		}
	}
}

.hr-line {
	display: none;
}

.experience {
	display: flex;
	align-items: flex-end;
}

.row-2 {
	background-color: #e98424;
	padding: 60px 130px;
	z-index: 20;
	border-radius: 80px 80px 0 0;

	&__header {
		display: flex;
		justify-content: center;
	}

	&__content {
		position: relative;
		text-align: center;

		h1 {
			font-size: 68px;
			padding-bottom: 10px;
			padding: 5px;
		}
	}

	&__phone {
		display: flex;
		justify-content: space-between;

		input {
			width: 60%;
			padding: 20px 50px;
			border-radius: 35px;
			border: none;
			height: -webkit-fill-available;
		}

		button {
			width: 25%;
			padding: 20px 60px;
			font-size: 16px;
			border-radius: 35px;
			height: -webkit-fill-available;
			border: none;
			background-color: #0375b0;
			color: white;
		}
	}
}

.check-box {
	display: flex;
	padding-left: 20px;

	input {
		width: 20px;
	}
}

.con-2 {
	&__content {
		h1 {
			font-size: 80px;
		}
	}
}

.row-3-1 {
	padding: 0 80px;
}

.row-3-2 {
	padding: 0 80px;
}

.row-3 {
	background-color: #fff;
	padding: 100px 80px;
	border-radius: 50px 50px 0 0;

	&__header {
		h2 {
			color: #424143;
			padding-left: 90px;
		}

		position: relative;
	}
}

.step1 {
	color: #424143;
	display: flex;
	align-items: center;

	&__image {
		width: 40%;
		z-index: 20;

		img {
			width: 100%;
		}
	}

	&__content-main {
		width: 65%;
		background-color: #0375b0;
		color: #fff;
		padding: 60px 0 60px 0px;
		border-radius: 0 60px 60px 0;
		display: flex;
		justify-content: center;
	}

	&__content {
		justify-content: flex-start;
		align-items: center;

		h4 {
			font-size: 36px;
		}

		img {
			width: 25%;
			padding: 0 10px;
			height: -webkit-fill-available;
		}
	}
}

.step2 {
	color: #fff;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;

	&__image {
		width: 35%;
		display: flex;

		img {
			width: 100%;
		}
	}

	&__content-main {
		width: 65%;
		background-color: #e98424;
		padding: 110px 0;
		display: flex;
		justify-content: center;
		border-radius: 50px 0 0 50px;
		z-index: 20;
	}

	&__content {
		align-items: center;

		h5 {
			font-size: 36px;
			font-weight: 600;
		}

		img {
			width: 25%;
			padding: 0 10px;
			height: -webkit-fill-available;
		}
	}
}

.step3 {
	color: #fff;
	background-color: #2ca74a;
	display: flex;
	// justify-content: space-between;
	align-items: center;
	padding: 50px 10% 0 10%;
	border-radius: 80px;

	&__image {
		width: 40%;
		display: flex;
		z-index: 20;
		justify-content: center;

		img {
			width: 100%;
		}
	}

	&__content-main {
		width: 60%;
		padding-left: 40px;
		border-radius: 50px 0 0 50px;
		position: relative;
	}

	&__content {
		display: flex;
		padding-left: 50px;
		flex-direction: column;

		p {
			font-size: 26px;
		}

		img {
			width: 25%;
			padding: 0 10px;
			height: -webkit-fill-available;
		}
	}
}

.fa {
	font-size: 34px;
	border: 2px solid white;
	padding: 3px 5px;
	border-radius: 50%;
	color: #2ca74a;
	background-color: white;
	margin-right: 10px;
	z-index: 20;
}

.fa-icon {
	display: flex;
	align-items: center;

	h4 {
		font-size: 52px;
	}
}

.container1 {
	padding: 80px 200px;
}

.con-2 {
	text-align: center;

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__link {
		background-color: #0375b0;
		display: flex;
		border-radius: 10px;
		justify-content: center;
		width: 620px;
		margin: 40px 0;

		a {
			text-decoration: none;
			cursor: pointer;
			width: 40%;

			img {
				width: 100%;
			}
		}
	}

	&__link-content {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		a {
			width: 100%;
			p {
				color: white;
				font-size: 23px;
			}
		}

		img {
			width: 35%;
		}
	}
}

.container2 {
	padding: 0 140px;
}

.con-3 {
	&__header {
		h2 {
			padding-left: 96px;
		}
	}

	&__container {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 50px;
		position: relative;
	}

	&__content {
		width: 55%;
		background-color: #2ca74a;
		color: #fff;
		display: flex;
		justify-content: center;
		padding: 70px 0px;
		border-radius: 50px 0 0 50px;
		margin-top: 45px;
	}

	&__content-main {
		h2 {
			font-size: 42px;
		}

		img {
			width: 30%;
			height: -webkit-fill-available;
			padding-left: 10px;
		}
	}

	&__image {
		width: 45%;
		display: flex;

		img {
			width: 100%;
		}
	}
}

::-webkit-scrollbar {
	width: 5px;
	height: 7px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ffffff00;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #ffffff00;
}

::-webkit-scrollbar-thumb:hover {
	background: #ffffff00;
}

.con-4 {
	background-color: #fff;
	padding: 60px 0px;
	border-radius: 80px 80px 0 0;
	// height: 90vh;
	// overflow-y: scroll;

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 40px;
	}

	&__image {
		width: 50%;
		z-index: 20;

		img {
			width: 100%;
		}
	}

	&__content {
		width: 50%;

		h2 {
			font-size: 56px;
			color: #424143;
		}

		h1 {
			font-size: 66px;
			color: #e98424;
		}
	}
}

.div-hr {
	position: relative;
}

.hr {
	border-left: 5px solid white;
	height: 230px;
	position: absolute;
	top: -230px;
	left: 320px;
	z-index: 1;
}

.hr1 {
	border-left: 5px solid #2ca74a;
	height: 1200px;
	position: absolute;
	top: 26px;
	left: 306px;
	z-index: 1;
}

.hr2 {
	border-left: 5px solid white;
	height: 234px;
	position: absolute;
	top: 58px;
	left: 57px;
	z-index: 1;
}

.hr3 {
	display: none;
	border-left: 5px solid #2ca74a;
	height: 135px;
	position: absolute;
	top: 346px;
	left: 172px;
	z-index: 1;
}

.dot {
	position: absolute;
	top: 253px;
	font-size: 40px;
	left: 49px;
}

span {
	color: #2ca74a;
}

li {
	list-style-type: disc;
}

.popup {
	display: flex;
	justify-content: center;

	h6 {
		color: #0375b0;
		font-size: 26px;
		cursor: pointer;
		font-family: "Bahnschrift semibold";
	}

	h6:hover {
		color: #fff;
	}

	li {
		list-style-type: disc;
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.cont {
		padding: 50px 20px 0 20px;
	}

	.colm {
		padding: 0 0px;
	}

	.row-cont {
		padding: 0 0px;
	}

	.line {
		display: none;
	}

	.popup {
		h6 {
			margin: 5px 0;
		}
	}

	.popup-1 {
		display: flex;
		// justify-content: center;
		padding-left: 10%;
	}

	.popup-2 {
		display: flex;
		// justify-content: center;
		padding-left: 10%;
	}

	.popup-3 {
		display: flex;
		// justify-content: center;
		padding-left: 10%;
	}

	.experience {
		display: flex;
		align-items: flex-end;
		justify-content: center;

		p {
			padding: 0 !important;
		}
	}

	.row-1 {
		&__container {
			flex-direction: column-reverse;
		}

		&__content {
			margin-top: 0;
			width: 100%;
			border-radius: 0 0 50px 50px;
			padding: 30px 20px 30px 20px;
			text-align: center;

			h2 {
				font-size: 30px;
			}

			h3 {
				font-size: 24px;
				padding-bottom: 15px;
			}

			p {
				font-size: 16px;
				padding: 0 35px;
			}
		}

		&__image {
			width: 100%;
		}
	}

	.row-2 {
		padding: 60px 0px;
		margin: 0 0 40px 0;
		border-radius: 40px;

		&__header {
			flex-direction: column;
		}

		&__content {
			h1 {
				font-size: 36px;
				padding-bottom: 0px;
				margin: 0;
			}
		}
	}

	.hr-line {
		display: flex;
		justify-content: center;

		hr {
			margin: 8px 0;
			width: 15%;
			border: 1px solid #0375b0;
			background-color: #0375b0;
		}
	}

	.popup {
		flex-direction: column;
		padding-top: 30px;
	}

	.div-hr {
		display: flex;
		justify-content: center;
	}

	.hr {
		border-left: 6px solid white;
		height: 50px;
		position: relative;
		z-index: 1;
		top: 0;
		left: 0;
	}

	.row-3 {
		padding: 30px 10px 0 10px;
		border-radius: 30px 30px 0 0;

		&__main {
			padding-bottom: 70px;
		}

		&__header {
			text-align: center;

			h2 {
				font-size: 30px;
				padding-left: 0;
				color: #424143;
			}
		}
	}

	.row-3-1 {
		padding: 0 10px;
	}

	.row-3-2 {
		padding: 0 10px;
	}

	.step1 {
		flex-direction: column;
		position: relative;

		&__content-main {
			width: 100%;
			padding: 30px 0;
			border-radius: 0 0 30px 30px;
			z-index: 30;
			position: absolute;
			top: 240px;
		}

		&__image {
			width: 100%;
		}

		&__content {
			text-align: center;

			h4 {
				font-size: 24px;
				// margin: 0px 0;
				margin: 3px 0;
				font-weight: 700;
				font-family: "Bahnschrift SemiBold";
			}
		}
	}

	.step2 {
		flex-direction: column;
		margin-top: 180px;

		&__content-main {
			padding: 40px 6%;
			width: 100%;
			border-radius: 0 0 50px 50px;
		}

		&__content {
			text-align: center;

			h5 {
				font-size: 24px;
				margin: 3px 0;
				font-weight: 700;
				font-family: "Bahnschrift SemiBold";
			}
		}

		&__image {
			width: 100%;

			img {
				z-index: 30;
			}
		}
	}

	.step3 {
		flex-direction: column;
		margin-top: 110px;
		padding: 0;
		border-radius: 40px;

		&__content-main {
			padding: 20px 10px 40px 10px;
			width: 100%;
			border-radius: 0 0 50px 50px;

			h4 {
				font-size: 24px;
				font-weight: 600;
			}
		}

		&__content {
			p {
				font-size: 18px;
			}
		}

		&__image {
			width: 100%;
		}
	}

	.hr2 {
		border-left: 4px solid white;
		height: 175px;
		position: absolute;
		top: 59px;
		left: 23px;
		z-index: 1;
	}

	.fa {
		font-size: 24px;
		padding: 2px 3px;
	}

	.dot {
		position: absolute;
		top: 205px;
		font-size: 32px;
		left: 17px;
	}

	.container1 {
		padding: 100px 20px;
	}

	.con-2 {
		&__content {
			text-align: center;

			h1 {
				font-size: 32px;
				margin: 3px 0;
			}

			h2 {
				font-size: 26px;
				margin: 3px 0;
			}
		}

		&__link {
			display: block;
			width: 75%;
			margin: 40px 0 0 0;

			img {
				width: 100%;
			}
		}

		&__link-content {
			display: block;
			width: 100%;
		}
	}

	.hr1 {
		display: none;
		// border-left: 5px solid #2ca74a;
		// height: 1200px;
		// position: absolute;
		// top: 22px;
		// left: 215px;
		// z-index: 1;
	}

	.container2 {
		padding: 10px 20px;
	}

	.con-3 {
		&__container {
			flex-direction: column-reverse;
		}

		&__image {
			width: 100%;
		}

		&__content-main {
			text-align: center;

			h2 {
				font-size: 24px;
				margin: 0px 0;
			}
		}

		&__content {
			width: 100%;
			padding: 50px 0px;
			border-radius: 0 0 50px 50px;
			margin-top: 0;
		}
	}

	.con-4 {
		padding: 40px 20px 20px 20px;
		margin-top: 30px;
		border-radius: 0;

		&__container {
			flex-direction: column;
		}

		&__image {
			width: 100%;
		}

		&__content {
			width: 100%;
			z-index: 20;
			text-align: center;

			h2 {
				font-size: 32px;
			}

			h1 {
				font-size: 36px;
			}
		}
	}

	.hr3 {
		border-left: 5px solid #2ca74a;
		height: 44px;
		position: absolute;
		top: 480px;
		left: 135px;
		z-index: 1;
	}
}

@media screen and (min-width: 1020px) and (max-width: 1420px) {
	.cont {
		padding: 80px 4% 0 4%;
	}

	.row-1 {
		&__content {
			width: 55%;
		}

		&__image {
			width: 40%;
		}
	}

	.container2 {
		padding: 0 7%;
	}

	.col {
		padding: 0 5%;
		padding-top: 40px;
	}

	.hr {
		height: 128px;
		top: -128px;
	}

	.colm {
		padding: 0 5%;
	}

	.row-cont {
		padding: 0 6%;
	}

	.row-2 {
		padding: 6% 8%;
	}

	.row-3 {
		padding: 8% 5%;
	}

	.row-3-1 {
		padding: 0 6%;
	}

	.row-3-2 {
		padding: 0 6%;
	}

	.hr1 {
		top: 26px;
		left: 306px;
	}

	.container1 {
		padding: 8% 10%;
	}

	.con-3 {
		&__content {
			width: 60%;
		}

		&__image {
			width: 40%;
		}
	}

	// .hr3 {
	//   // top: 280px;
	//   // height: 50%;
	//   height: 60px;
	// }
	.con-4 {
		padding: 5% 8%;

		&__content {
			width: 55%;
		}

		&__content {
			h1 {
				font-size: 60px;
				color: #e98424;
			}

			h1 {
				font-size: 50px;
				color: #e98424;
			}
		}
	}

	.hr2 {
		top: 55px;
	}
}

#hide {
	position: fixed;
	top: 50vh;
	left: 40vh;
}

#height {
	position: fixed;
	top: 5vh;
	left: 5vh;
}

#container {
	min-height: 200vh;
}

@media screen and (min-width: 768px) and (max-width: 1020px) {
	.cont {
		padding: 30px 2% 0 2%;
	}

	.colm {
		padding: 0 5%;
	}

	.row-cont {
		padding: 0 5%;
	}

	.row-1 {
		padding: 0 3%;

		&__content {
			width: 55%;
			padding: 30px 0;
			margin-top: 30px;

			h2 {
				font-size: 32px;
			}

			h3 {
				font-size: 28px;
			}

			p {
				font-size: 14px;
			}
		}

		&__image {
			width: 40%;
		}
	}

	.hr {
		height: 130px;
		top: -130px;
		left: 220px;
	}

	.row-2 {
		padding: 40px;

		&__content {
			h1 {
				font-size: 50px;
			}
		}
	}

	.popup {
		h6 {
			font-size: 18px;
		}
	}

	.row-3 {
		padding: 0 2% 5% 2%;

		&__main {
			padding-top: 40px;
		}

		&__header {
			h2 {
				padding-left: 20px;
			}
		}
	}

	.row-3-1 {
		padding: 0 4%;
	}

	.row-3-2 {
		padding: 0 30px;
	}

	.step1 {
		&__content-main {
			padding: 30px 0;
		}

		&__content {
			h4 {
				font-size: 28px;
			}
		}
	}

	.step2 {
		&__content-main {
			padding: 50px 0;
		}

		&__content {
			h5 {
				font-size: 28px;
			}
		}
	}

	.hr1 {
		left: 236px;
		height: 1000px;
	}

	.fa {
		font-size: 24px;
	}

	.fa-icon {
		h4 {
			font-size: 36px;
		}
	}

	.hr2 {
		height: 225px;
		top: 45px;
		left: 55px;
	}

	.dot {
		top: 240px;
		font-size: 34px;
		left: 48.6px;
	}

	.container1 {
		padding: 80px 0px;
	}

	.col {
		padding: 0 2%;
	}

	.container2 {
		padding: 0 4%;
	}

	.con-3 {
		&__content {
			padding: 50px 0;
		}

		&__content-main {
			h2 {
				font-size: 36px;
			}
		}
	}

	.con-4 {
		border-radius: 30px 30px 0 0;

		&__image {
			width: 45%;
		}

		&__content {
			width: 55%;

			h1 {
				font-size: 46px;
			}

			h2 {
				font-size: 42px;
			}
		}
	}
}

@media screen and (min-width: 375px) and (max-width: 420px) {
	.step1 {
		&__content-main {
			top: 310px;
		}
	}

	.hr1 {
		left: 243px;
	}

	.hr3 {
		top: 586px;
		left: 164px;
	}
}

@media screen and (min-width: 425px) and (max-width: 760px) {
	.step1 {
		&__content-main {
			top: 360px;
		}
	}

	.hr1 {
		left: 268px;
	}

	.hr3 {
		top: 642px;
		left: 188px;
	}
}

@media screen and (min-width: 1600px) and (max-width: 2560px) {
	.row-1 {
		&__image {
			width: 40%;
		}

		&__content {
			width: 45%;
			margin-top: 140px;

			h2 {
				font-size: 60px;
			}

			p {
				font-size: 30px;
			}

			h3 {
				font-size: 62px;
			}
		}
	}

	.hr {
		height: 450px;
		top: -450px;
		left: 450px;
	}

	.con-4 {
		&__content {
			h1 {
				font-size: 82px;
			}

			h2 {
				font-size: 76px;
			}
		}
	}

	.con-3 {
		&__content-main {
			h2 {
				font-size: 66px;
			}
		}
	}

	.hr1 {
		height: 2222px;
	}

	.step1 {
		&__content {
			h4 {
				font-size: 60px;
			}
		}
	}

	.step2 {
		&__content {
			h5 {
				font-size: 60px;
			}
		}
	}

	.step3 {
		&__content {
			padding-left: 70px;

			p {
				font-size: 40px;
			}
		}
	}

	.fa-icon {
		h4 {
			font-size: 72px;
		}
	}

	.fa {
		font-size: 50px;
		padding: 5px 8px;
	}

	.hr2 {
		height: 300px;
		top: 82px;
		left: 68px;
	}

	.dot {
		top: 350px;
		font-size: 45px;
		left: 59px;
	}

	.row-2 {
		&__content {
			h1 {
				font-size: 90px;
			}
		}
	}

	// .__react_component_tooltip {
	//   font-size: 36px !important;
	// }
	.popup {
		h6 {
			font-size: 32px;
		}

		&__list-li {
			font-size: 26px;
		}
	}
}

// *,
// *::before,
// *::after {
//   margin: 0;
//   paddin: 0;
//   box-sizing: border-box;
// }

// body {
//     font-family: 'Arial', Helvetica, Sans-Serif;
//     color: #ffffff;
//     height: auto;
// }

// custom
.relative {
	position: relative;
}
.custom-bg::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 40vw;
	height: 100%;
	background-color: #2ca74a;
	z-index: 0;
}
.custom-color {
	color: #fff !important;
}

.custom-bg-2::before {
	content: " ";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	z-index: 0;
}

.intro-container {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;

	.intro-left {
		order: 0;
		background-color: #fff;
		padding: 80px;
		border-radius: 40px 0 0 40px;
		width: 100%;

		h3 {
			font-size: 50px;
			color: black;

			.text-icon {
				height: 50px;
			}

			span {
				color: #2ca74a;
			}
		}

		h5 {
			font-size: 35px;
			color: #000;
		}

		.intro-flex {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				display: block;

				span {
					display: block;
					background-color: #2ca74a;
					color: #fff;
					padding: 0 1rem;
					font-size: 30px;
				}
			}
		}
	}

	.intro-right {
		background-color: #fff;
		border-radius: 0 40px 40px 0;
		width: 100%;
		order: 1;

		.flex-container {
			height: 100%;
			background-color: #0375b0;
			border-radius: 40px;
			padding: 40px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.intro-icon {
				height: 150px;
			}
			.whatsapp-icon {
				display: block;
			}
			.hands-icon {
				display: none;
			}

			h3 {
				font-size: 50px;
				line-height: 40px;
				font-weight: 600;
				font-family: "Bahnschrift SemiBold";
			}
		}
	}
}

.offer-container {
	background-color: #fff;
	width: 100%;

	.offer {
		text-align: center;
		padding: 0 30px;
	}
}

@media screen and (max-width: 767px) {
	.custom-bg::before {
		width: 55vw;
	}
	.custom-color {
		color: #2ca74a !important;
	}
	.intro-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		text-align: center;

		.intro-left {
			order: 0;
			border-radius: 40px 40px 0 0;
			padding: 40px;

			h3 {
				font-size: 30px;
				color: black;

				.text-icon {
					height: 30px;
				}
			}

			h5 {
				font-size: 20px;
			}

			.intro-flex {
				display: block;

				p {
					span {
						font-size: 16px;
						display: inline-block;
					}
				}
			}
		}

		.intro-right {
			order: 1;
			border-radius: 0 0 40px 40px;

			.flex-container {
				// padding: 40px;
				padding: 10px;
				flex-direction: row;

				.intro-icon {
					// height: 100px;
					height: 60px;
				}

				.whatsapp-icon {
					display: none;
				}
				.hands-icon {
					display: block;
					height: 30px;
					background-color: orange;
					border-radius: 50%;
					margin-right: 10px;
				}

				h3 {
					font-size: 30px;
					line-height: 20px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1020px) {
	.intro-container {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;

		.intro-left {
			width: 100%;
			order: 0;
			background-color: #fff;
			padding: 40px;
			border-radius: 40px 0 0 40px;

			h3 {
				font-size: 30px;
				color: black;

				.text-icon {
					height: 30px;
				}
			}

			h5 {
				font-size: 25px;
			}

			.intro-flex {
				display: block;

				p {
					span {
						font-size: 20px;
						text-align: center;
					}
				}
			}
		}

		.intro-right {
			order: 1;
			border-radius: 0 40px 40px 0;

			.flex-container {
				padding: 30px;

				.intro-icon {
					height: 100px;
				}

				h3 {
					font-size: 30px;
					line-height: 20px;
				}
			}
		}
	}
}

@media screen and (min-width: 1020px) and (max-width: 1600px) {
	.intro-container {
		.intro-left {
			padding: 40px;

			.intro-flex {
				display: block;

				p {
					font-size: 23px;
					text-align: center;
				}
			}
		}
	}
}
