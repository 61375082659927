.privacy {
  margin: 0 100px;
  padding-top: 60px;
  h6 {
    font-size: 20px;
    color: #424143;
  }
  p {
    font-size: 16px;
  }
  &__heading {
    background-color: #e98424;
    text-align: center;
    padding: 60px;
    border-radius: 50px 50px 0 0;
  }
  &__content1 {
    color: #424143;
    background-color: #fff;
    padding: 50px 50px 0 50px;
    border-radius: 50px 50px 0 0;
    h6 {
      color: #424143;
      padding-top: 20px;
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }
  &__content2 {
    color: #424143;
    background-color: #fff;
    padding: 0 50px 50px 50px;
    border-radius: 0 0 50px 50px;
    h6 {
      color: #424143;
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }
}
.con {
  padding: 120px 100px 0 100px;
}

@media screen and (min-width: 1020px) and (max-width: 1420px) {
  .con {
    padding: 80px 7% 0 7%;
  }
}

@media screen and (min-width: 320px) and (max-width: 760px) {
  .con {
    padding: 50px 20px 0 20px;
  }
  .privacy {
    margin: 0;
    padding-top: 30px;
    &__heading {
      padding: 40px 20px;
      border-radius: 30px 30px 0 0;
      h1 {
        font-size: 30px;
      }
    }
    &__content1 {
      padding: 40px 20px;
      border-radius: 0;
      h6 {
        font-size: 20px;
        color: #424143;
      }
      p {
        font-size: 16px;
        // text-align: justify;
        text-align: justify;
      }
    }
    &__content2 {
      padding: 10px 20px;
      h6 {
        font-size: 20px;
        color: #424143;
        padding-top: 15px;
      }
      p {
        font-size: 16px;
        // text-align: justify;
        text-align: justify;
      }
    }
  }
}
