.footer {
	background-color: #424143;

	&__container {
		padding: 80px;
		text-align: center;

		h5 {
			font-weight: 300;
			padding-bottom: 10px;
			color: #fff;
			padding-right: 10px;
			cursor: pointer;
			font-size: 24px;
		}

		h6 {
			font-weight: 300;
			padding-bottom: 10px;
			color: #fff;
			padding-right: 10px;
			cursor: pointer;
			font-size: 22px;
		}
	}

	&__heading {
		display: flex;
		justify-content: center;
	}
}

@media screen and (min-width: 320px) and (max-width: 760px) {
	.footer {
		&__heading {
			flex-direction: column;
			padding-bottom: 30px;
		}

		&__line {
			display: none;
		}

		&__container {
			padding: 60px 20px;
			text-align: left;

			h5 {
				margin: 5px 0;
				padding-bottom: 10px;
				font-size: 20px;
			}

			h6 {
				font-size: 18px;
				margin: 15px 0;
			}
		}
	}
}
